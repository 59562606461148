import React, { Component } from 'react';

class Donate extends Component {

  render() {
    return (
      <div>Donations</div>
    );
  }

}

export default Donate;
