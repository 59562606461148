import React, { Component } from "react";
import { Form, Checkbox, Radio, Button, Dropdown, Input } from "semantic-ui-react";
import "../App.css";
import defaultPicture from "../assets/data.svg";
import { toast } from "react-toastify";

const colors = [
  "#FFFFFF",
  "#F8EEE4",
  "#FAE572",
  "#FF9140",
  "#F8B1AF",
  "#DE0A0A",
  "#BC2A84",
  "#12028B",
  "#A7BFD6",
  "#AEEAD7",
  "#0D6F33",
  "#5F3A32",
  "#C4C4C4",
  "#656464",
  "#000000",
];
const weights = [
  { key: "lb", text: "lb", value: "lb" },
  { key: "kg", text: "kg", value: "kg" },
  { key: "oz", text: "oz", value: "oz" },
];

class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      physical: false,
      selectedColors: [],
      unit: "lb",
      numOfLocations: [1],
      counter: 1,
      numOfSizes: [1],
      counterS: 1,
      mySizes: [],
      numOfThemes: [1],
      counterT: 1,
      myThemes: [],
      api: props.api,
      currency: "USD",
    };
  }

  collectionOptions = [];
  categoryOptions = {};
  sizeOptions = [];
  currencies = [];

  componentDidMount() {
    document.title = "Boonzie";
    let url = this.state.api + "products/choices";
    let xhr = new XMLHttpRequest();
    xhr.responseType = "json";
    xhr.open("GET", url);

    xhr.onload = () => {
      let response = xhr.response;
      if (xhr.status === 200) {
        for (let x = 0; x < response["size"].length; x++) {
          let arr = response["size"][x];
          this.sizeOptions.push({
            key: arr[0],
            value: arr[0],
            text: arr[1],
          });
        }

        for (let x = 0; x < response["collection"].length; x++) {
          let arr = response["collection"][x];
          this.collectionOptions.push({
            key: arr[0],
            value: arr[0],
            text: arr[1],
          });
        }

        for (let key in response["category"]) {
          let arr = response["category"][key];
          let data = [];
          for (let y = 0; y < arr.length; y++) {
            data.push({
              key: arr[y][0],
              value: arr[y][0],
              text: arr[y][1],
            });
          }
          this.categoryOptions[key] = data;
        }

        for (let x = 0; x < response["currencies"].length; x++) {
          let value = response["currencies"][x];
          this.currencies.push({
            key: value,
            value: value,
            text: value
          });
        }

        toast.success("Information loaded");
      } else {
        toast.error(
          "There was an error loading information. Please refresh the page."
        );
      }
    };
    xhr.send();
  }

  handleCat = value => {
    this.setState({
      category: value
    });
  };
  handleCol = value => {
    this.setState({
      collection: value
    });
  };
  handleWeight = value => {
    this.setState({
      unit: value
    });
  };
  handleCurrency = value => {
    this.setState({
      currency: value
    });
  };
  handleInputs = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  toggle = () => {
    this.setState({
      physical: !this.state.physical,
    });
  };

  handleImageChange(event) {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        picture: reader.result,
        preview: true,
        filename: file.name,
      });

      // let url = this.state.api + "products/upload";
      // let xhr = new XMLHttpRequest();
      // let payload = {
      //   image: reader.result,
      // };

      // xhr.open("POST", url);
      // xhr.setRequestHeader("Content-Type", "application/json");
      // xhr.send(JSON.stringify(payload));
      // xhr.onload = () => {
      //   if (xhr.status === 201) {
      //     toast.success("File successfully uploaded");
      //   } else if (xhr.status === 400) {
      //     toast.error("There was an error while uploaded the file");
      //   } else {
      //     toast.error(
      //       "There was a server error. Please refresh the page"
      //     );
      //   }
      // };
      // xhr.onerror = () => {
      //   toast.error("Upload failed");
      // };
    };
    reader.readAsDataURL(file);
  }

  onSubmit = () => {
    let url = this.state.api + "products";
    let xhr = new XMLHttpRequest();
    let payload = {
      title: this.state.title,
      description: this.state.description,
      price: this.state.price,
      barcode: this.state.barcode,
      inventory_quantity: this.state.quantity,
      sku: this.state.sku,
      physical_product: this.state.physical,
      weight: this.state.weight,
      color: this.state.selectedColors,
      collection: this.state.collection,
      category: this.state.category,
      min_age: this.state["min-age"],
      max_age: this.state["max-age"],
      brand: this.state.brand,
      gender: this.state.gender,
      name: this.state.name,
      store_quantity: this.state.quantity2,
      image: this.state.picture,
      filename: this.state.filename,
      currency: this.state.currency,
      theme: this.state.myThemes,
    };

    let address = [];
    for (let x = 1; x <= this.state.numOfLocations.length; x++) {
      let data = {
        street: this.state["street_" + x],
        city: this.state["city_" + x],
        state: this.state["state_" + x],
        zipcode: this.state["zipcode_" + x],
      };
      address.push(data);
    }
    payload["address"] = address;

    let sizes = [];
    for (let x = 1; x <= this.state.numOfSizes.length; x++) {
      let data = {
        size: this.state["size_" + x],
        quantity: this.state["size_quantity_" + x]
      }
      console.log(data);
      sizes.push(data);
    }
    payload["size"] = sizes;

    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(payload));
    xhr.onload = () => {
      let response = JSON.parse(xhr.responseText);
      if (xhr.status === 201) {
        toast.success("Data successfully saved.");
      } else if (xhr.status === 400) {
        if (response.length > 1) {
          for (let key in response) {
            toast.error(String(key) + ": " + response[key][0]);
          }
        } else {
          for (let key in response) {
            toast.error(String(key) + ": " + response[key]);
          }
        }
      } else if (xhr.status === 500) {
        toast.error("There was a server error. Please refresh the page");
      } else {
        toast.error("An error occured. Please refresh the page.");
      }
    };
    xhr.onerror = () => { toast.error("Upload failed"); };
  };

  color = () => {

    let myColor = colors.map(color => {
      let colors = this.state.selectedColors;
      return (
        <div
          key={color}
          className="color-style"
          style={{
            background: color,
            border:
              (colors.includes(color))
                ? 'solid DodgerBlue 2px'
                : 'solid darkgrey 2px'
          }}
          onClick={() => {
            this.handleColor(color);
          }}
        />
      );
    });
    return myColor;
  };

  handleColor = color => {
    const { selectedColors } = this.state;
    let colors = selectedColors;

    if (colors.includes(color)) {

      colors.splice(colors.indexOf(color), 1);
      this.setState({
        selectedColors: colors,
      });

    } else {
      colors.push(color)
      this.setState({
        selectedColors: colors,
      });
    }

  };

  renderLocations = () => {
    const { numOfLocations } = this.state;
    let myLocations = numOfLocations.map(location => {
      return (
        <div key="location">
          <h3 className="sections">{`LOCATION ${location}`}</h3>
          <Form.Input
            name={`street_${location}`}
            placeholder="Street"
            onChange={this.handleInputs}
          />
          <Form.Group widths="equal">
            <Form.Input
              name={`city_${location}`}
              placeholder="City"
              onChange={this.handleInputs}
            />
            <Form.Input
              name={`state_${location}`}
              placeholder="State"
              onChange={this.handleInputs}
            />
            <Form.Input
              name={`zipcode_${location}`}
              placeholder="Zip Code"
              onChange={this.handleInputs}
            />
          </Form.Group>
        </div>
      );
    });

    return myLocations;
  };

  addLocation = e => {
    const { numOfLocations, counter } = this.state;
    numOfLocations.push(counter + 1);
    this.setState({
      counter: this.state.counter + 1,
    });
  };

  renderThemes = () => {
    const { numOfThemes } = this.state;
    let myThemes = numOfThemes.map(theme => {
      return (
        <Form.Input
          key={`theme_${theme - 1}`}
          name={`theme_${theme - 1}`}
          className="my-themes"
          placeholder="Enter a Theme"
          onChange={this.handleTheme}
        />
      );
    });

    return myThemes;
  };

  handleTheme = (event) => {
    const { myThemes } = this.state;
    myThemes[parseInt(event.target.name.substr(-1))] = event.target.value;
    this.setState({ myThemes });
  }

  addTheme = e => {
    const { numOfThemes, counterT } = this.state;
    numOfThemes.push(counterT + 1);
    this.setState({
      counterT: this.state.counterT + 1,
    });
  };

  renderSizes = () => {
    const { numOfSizes } = this.state;
    let mySizes = numOfSizes.map(size => {
      return (<Form.Group inline>
        <Form.Select
          key={`selectsize_${size}`}
          label="SIZE"
          name={`size_${size}`}
          options={this.sizeOptions}
          placeholder="Select a Size"
          onChange={(e, { value }) => this.handleSize(value, size)}
        />
        <Form.Input
          key={`inputsize_${size}`}
          label="QUANTITY"
          type="number"
          placeholder='Amount in Stock'
          name={`size_quantity_${size}`}
          onChange={this.handleInputs}
        />
      </Form.Group>
      );
    });
    return mySizes;
  };

  handleSize = (value, s) => {
    this.setState({
      [`size_${s}`]: value
    });
  }

  addSize = e => {
    const { numOfSizes, counterS } = this.state;
    numOfSizes.push(counterS + 1);
    this.setState({
      counterS: this.state.counterS + 1,
    });
  };
  handleGender = (e, { value }) => this.setState({ value });

  render() {
    const { value } = this.state;
    return (
      <div className="form-data">
        <div className="left">
          <h2>ADD PRODUCT</h2>

          <Form className="form">
            <Form.Input
              label="TITLE"
              name="title"
              placeholder="SOFT TOY, PINK BEAR"
              onChange={this.handleInputs}
            />
            <Form.Input
              label="BRAND"
              name="brand"
              placeholder="Enter a Brand"
              onChange={this.handleInputs}
            />
            <Form.TextArea
              className="description"
              label="DESCRIPTION"
              name="description"
              onChange={this.handleInputs}
            />
            <Form.Group inline>
              <Form.Input
                className="sizing"
                label="PRICE"
                name="price"
                placeholder="$0.00"
                type="number"
                step="0.01"
                onChange={this.handleInputs}
              />
              <Form.Select
                className="sizing currency-margin-left"
                label="CURRENCY"
                name="currency"
                options={this.currencies}
                defaultValue="USD"
                placeholder="USD"
                onChange={(e, { value }) =>
                  this.handleCurrency(value)
                }
              />
            </Form.Group>

            <h3 className="sections">INVENTORY</h3>
            <Form.Group>
              <Form.Input
                className="sizing-half"
                label="BARCODE ( ISBN,UPC, GITIN, ETC )"
                name="barcode"
                onChange={this.handleInputs}
              />
              <div className="lable">
                <Form.Input
                  label="QUANTITY"
                  name="quantity"
                  type="number"
                  step="0.01"
                  onChange={this.handleInputs}
                />
              </div>
            </Form.Group>
            <Form.Input
              className="sizing-half"
              label="SKU ( STOCKING KEEPING UNIT )"
              name="sku"
              onChange={this.handleInputs}
            />

            <h3 className="sections">SHIPPING</h3>
            <Form.Field
              control={Checkbox}
              label="THIS IS A PYSICAL PRODUCT"
              onClick={() => this.toggle()}
            />

            <h3 className="sections">WEIGHT</h3>
            <h4>USED TO CALCULATE SHIPPING RATES AT CHECKOUT</h4>
            <Input
              className="weight"
              label={
                <Dropdown
                  onChange={(e, { value }) =>
                    this.handleWeight(value)
                  }
                  defaultValue="lb"
                  options={weights}
                />
              }
              labelPosition="right"
              placeholder="0.0"
              name="weight"
              type="number"
              step="0.01"
              onChange={this.handleInputs}
            />

            <h3 className="sections">VARIANTS</h3>

            <Form.Select
              label="COLLECTION"
              name="collection"
              options={this.collectionOptions}
              placeholder="Select a Collection"
              onChange={(e, { value }) => this.handleCol(value)}
            />
            <Form.Select
              label="CATEGORY"
              name="category"
              options={this.categoryOptions[this.state.collection]}
              placeholder="Select a Category"
              onChange={(e, { value }) => this.handleCat(value)}
            />
            <Form.Group>
              <Form.Input
                label="MIN. AGE"
                name="min-age"
                onChange={this.handleInputs}
                type="number"
              />
              <Form.Input
                label="MAX. AGE"
                name="max-age"
                onChange={this.handleInputs}
                type="number"
              />
            </Form.Group>

            <label>THEMES</label>

            {this.renderThemes()}

            <div className="add-button-contain">
              <Button
                circular
                type="button"
                color="yellow"
                icon="add"
                size="large"
                onClick={e => this.addTheme(e)}
              />
              <h4 className="add-title">
                Add Another Theme
              </h4>
            </div>

            {this.renderSizes()}

            <div className="add-button-contain">
              <Button
                circular
                type="button"
                color="yellow"
                icon="add"
                size="large"
                onClick={e => this.addSize(e)}
              />
              <h4 className="add-title">
                Add Another Size
              </h4>
            </div>

            <h3 className="sections">GENDER</h3>
            <Form.Group inline>
              <Form.Field
                control={Radio}
                label="BOYS"
                value="M"
                checked={value === "M"}
                onChange={this.handleGender}
              />
              <Form.Field
                control={Radio}
                label="GIRLS"
                value="F"
                checked={value === "F"}
                onChange={this.handleGender}
              />
              <Form.Field
                control={Radio}
                label="NO SPECIFICATION"
                value="U"
                checked={value === "U"}
                onChange={this.handleGender}
              />
            </Form.Group>

            <h3 className="sections">ADD STORE</h3>
            <Form.Group>
              <Form.Input
                label="NAME"
                name="name"
                onChange={this.handleInputs}
              />
              <Form.Input
                label="QUANTITY"
                name="quantity2"
                onChange={this.handleInputs}
                type="number"
                step="0.01"
              />
            </Form.Group>

            {this.renderLocations()}

            <div className="add-button-contain">
              <Button
                circular
                type="button"
                color="yellow"
                icon="add"
                size="large"
                onClick={e => this.addLocation(e)}
              />
              <h4 className="add-title">
                Add Another Location
              </h4>
            </div>

            <Button color="blue" onClick={this.onSubmit}> SUBMIT </Button>
          </Form>
        </div>
        <div className="right">
          <div className="img-style">
            <img
              style={{ width: "100%", height: "50vh" }}
              src={
                this.state.preview
                  ? this.state.picture
                  : defaultPicture
              }
              alt="product"
            />
            <input
              ref={fileInput => (this.fileInput = fileInput)}
              style={{ display: "none" }}
              type="file"
              onChange={event => this.handleImageChange(event)}
            />

            <Button
              type="button"
              className="file-upload"
              color="yellow"
              onClick={() => this.fileInput.click()}
            >
              UPLOAD IMAGE
            </Button>
          </div>
          <div className="palette">
            <h3> COLOR </h3>
            <div className="color-selector">{this.color()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Data;
