import React, { useState } from "react";
import Footer from "./components/Footer.js";
import Data from "./components/Data.js";
import NavBar from "./components/NavBar.js";
import Home from "./components/Home.js";
import Categories from "./components/Categories.js";
import Admin from './components/Admin.js';
import TempLog from "./components/TempLog.js";
import { Route, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const api = process.env.REACT_APP_API || "http://localhost:8000/";
toast.configure({
  autoClose: 5000,
});

const App = () => {
  const [loggedIn, setLoggedIn] = useState(true);

  return (
    <div style={{ marginLeft: '20px' }}>
      {loggedIn ? (
        <div>
          <NavBar />
          <Link to="/data">TO TEMP DATA PAGE</Link>
          <br />
          <Link to="/bonnzie/admin">ADMIN PAGE</Link>
          <Route exact path="/" render={() => <Home />} />
          <Route
            path="/data"
            render={props => <Data {...props} api={api} />}
          />
          <Route path="/categories" render={() => <Categories />} />
          <Route
            exact
            path="/bonnzie/admin"
            render={props => <Admin {...props} api={api} />}
          />
          <Footer />
        </div>
      ) : (
        <TempLog />
      )}
    </div>
  );
}

export default App;
