import React, { Component } from 'react';

class Games extends Component {

  render() {
    return (
      <div>
        The Games
      </div>
    );
  }

}

export default Games;
