import React, { Component } from 'react';

class Classes extends Component {

  render() {
    return (
      <div>The Classes</div>
    );
  }

}

export default Classes;
