import React, { Component } from 'react';

class Subscription extends Component {

  render() {
    return (
      <div>Subscription</div>
    );
  }

}

export default Subscription;
