import React, { Component } from 'react';

class Essentials extends Component {

  render() {
    return (
      <div>The Essentials</div>
    );
  }

}

export default Essentials;
