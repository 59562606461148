import React, { Component } from 'react';
import { Modal, Form, Button} from 'semantic-ui-react';
import { Link } from "react-router-dom";
import swiggle from "../assets/HomePage/Swiggle_line.svg";
import "../App.css"

class StartHere extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };
  }
  nextPage=()=>{
    this.setState({
      page: this.state.page+1
    });
  }
  handleInput=(event)=>{
      this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { page } = this.state;

    return (
      <Modal size='large' trigger={
        (this.props.isStartHere)
          ?<h1 className="start-here">START HERE</h1>
          :(<Link className="nav-animation-contain registry">
            <h3 className="router-links" to="/registry">
              Registry
            </h3>
            <img
              alt="swiggle"
              className="swiggle-style registry-swiggle"
              src={swiggle}
            />
          </Link>)

      }>
        {
          (page === 1)
            ?(<div className="start-here-form-1 modal-div">
              <h1 className="find-registry-header">
                {
                  (this.props.isStartHere)
                    ? "LET'S GET THIS PARTY STARTED"
                    : "BUT FIRST...LET'S SET YOU UP"
                }

              </h1>
              <Form className="start-here-form-style">
                <h1 className="step-style" >Step 1</h1>
                <div className="form-group">
                  <div style={{ flex: 1,padding:"0 2% 0 2%"}}>
                    <label style={{fontSize: "1.3vw"}}>First</label>
                    <Form.Input className="start-here-modal-input" name="first-name" onChange = {this.handleInput}/>
                  </div>
                  <div style={{ flex: 1,padding:"0 2% 0 2%"}}>
                    <label style={{fontSize: "1.3vw"}}>Last</label>
                    <Form.Input className="start-here-modal-input" name="last-name" onChange = {this.handleInput}/>
                  </div>
                </div>
                <label style={{fontSize: "1.3vw",padding:"0 0 0 2%"}}>E-Mail</label>
                <div style={{padding:"0 2.5% 0 2.5%"}}>
                  <Form.Input className="start-here-modal-input" type="email" name="email" onChange = {this.handleInput}/>
                </div>
              </Form>
              <div className="start-here-button-contain">
                <Button className="next-button-1">JUST BROWSING</Button>
                <Button className="next-button-1" onClick={()=>this.nextPage()}>NEXT</Button>
              </div>
            </div>)
            : null
        }
        {
          (page === 2)
            ?(<div className="start-here-form-2 modal-div">
              <h1 className="find-registry-header">LET'S GET THIS PARTY STARTED</h1>
              <Form className="start-here-form-style-2">
                <h1 className="step-style">Step 2</h1>
                <div className="form-group">
                  <div style={{ flex: 1,padding:"0 2% 0 2%"}}>
                    <label style={{fontSize: "1.2vw"}}> Child First</label>
                    <Form.Input className="start-here-modal-input-2" name="first-name-2" onChange = {this.handleInput}/>
                  </div>
                  <div style={{ flex: 1,padding:"0 2% 0 2%"}}>
                    <label style={{fontSize: "1.2vw"}}>Last</label>
                    <Form.Input className="start-here-modal-input-2" name="last-name-2" onChange = {this.handleInput}/>
                  </div>
                </div>


                <label style={{fontSize: "1.2vw",padding:"0 0 0 2%"}}>Birthday</label>
                <div className="div-padding"><Form.Input type="date" className="start-here-modal-input-2" name="b-day" onChange = {this.handleInput}/></div>

                <label style={{fontSize: "1.2vw",padding:"0 0 0 2%"}}>Date of Event</label>
                <div className="div-padding"><Form.Input type="date" className="start-here-modal-input-2" name="event-date" onChange = {this.handleInput}/></div>


                <div className="start-here-button-contain-2">
                  <Button className="next-button-2">ADD CHILD</Button>
                  <Button className="next-button-2" onClick={()=>this.nextPage()}>NEXT</Button>
                </div>
              </Form>
            </div>)
            : null
        }
        {
          (page === 3)
            ?(<div className="start-here-form-3 modal-div">
              <h1 className="find-registry-header">LET'S GET THIS PARTY STARTED</h1>
              <Form className="start-here-form-style-2">
                <h1 className="step-style">Step 3</h1>

                <label style={{fontSize: "1.2vw",padding:"0 0 0 2%"}}>Username</label>
                <div className="div-padding"><Form.Input type="text" className="start-here-modal-input-2" name="user" onChange = {this.handleInput}/></div>

                <label style={{fontSize: "1.2vw",padding:"0 0 0 2%"}}>Password</label>
                <div className="div-padding"><Form.Input type="text" className="start-here-modal-input-2" name="pass" onChange = {this.handleInput}/></div>

                <label style={{fontSize: "1.2vw",padding:"0 0 0 2%"}}>Re-type Password</label>
                <div className="div-padding"><Form.Input type="text" className="start-here-modal-input-2" name="re-pass" onChange = {this.handleInput}/></div>


                <div className="start-here-button-contain-2">
                  <Button className="next-button-2" onClick={()=>this.nextPage()}>NEXT</Button>
                </div>
              </Form>
            </div>)
            : null
        }
      </Modal>
    );
  }

}

export default StartHere;
