import React, { Component } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react'
import '../App.css'

class FindRegistry extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSearch = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <Modal className="modal" size='large' trigger={<Button className="find-registry">FIND REGISTRY </Button>}>
        <div className="modal-div">
          <h1 className="find-registry-header">LET'S GET THIS PARTY STARTED</h1>

          <Form className="modal-form-contain registry-modal-style">
            <label style={{fontSize: "1.3vw" }}>HOST NAME</label>
            <Form.Input className="modal-input" name="host" onChange = {this.handleSearch}/>
            <label style={{fontSize: "1.3vw" }}>BIRTHDAY BOY'S/GIRL'S NAME</label>
            <Form.Input className="modal-input" name='name' onChange = {this.handleSearch}/>
            <h1 style={{fontSize: "1.6vw",textAlign: 'center',margin:"0" }}>OR</h1>

            <label style={{fontSize: "1.2vw" }}>NICKNAME GIVEN</label>
            <Form.Input className="modal-input" name='nickname' onChange = {this.handleSearch}/>

            <div style={{textAlign: 'center'}}>
              <Button className="find-registry-submit" type="submit">FIND REGISTRY</Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }

}

export default FindRegistry;
