import React, { Component } from 'react';
import SingleFund from './SingleFund.js'
import './category.css'
import defaultPicture from "../../assets/data.svg";
import { Button, Form, Radio } from 'semantic-ui-react'

class Funds extends Component {
constructor(props) {
  super(props);
  this.state ={
    display: 'none',
    mostWanted: false,
    hide: false
  };
}
  renderFunds=()=>{

  }

  displayForm = () => {
    this.setState({
      display: 'block'
    });
  }

  hideForm = () => {
    this.setState({
      display: 'none'
    });
  }

  handleImageChange(event) {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
        this.setState({
            file: file,
            picture: reader.result,
            preview: true
        });
    }
    reader.readAsDataURL(file)
  }

  handleInputs = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleWanted = () => this.setState({ mostWanted: !this.state.mostWanted });

  handleHide = () => this.setState({ hide: !this.state.hide });

  render() {
    const { display, hide, mostWanted } = this.state;
    return (
      <div className = "funds-contain">

        <h1 className="cash-fund">CASH FUND</h1>
        <div className="under-line" ></div>

        <section className="funds-bottom">

          <div className="side-bar">
            <h2 style={{fontSize: "2vw"}}> Search : </h2>
            <Form.Input className="search-funds"></Form.Input>
          </div>

          <div className="all-funds-contain">

            <div className='add-funds-box'>
              <Button
                circular
                color="yellow"
                className="fund-add"
                icon="add"
                size="massive"
              />
              <Button
                className='create-fund-button'
                color="yellow"
                onClick={()=>this.displayForm()}
              >CREATE FUND</Button>
            </div>

            {this.renderFunds()}
            <SingleFund />
            <SingleFund />
            <SingleFund />
            <SingleFund />



            <Form style={{display: display}} className="funds-form">
              <div className='fund-form-contain'>
                <div className="funds-form-left">

                  <div className = 'fund-image'>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={
                        this.state.preview
                          ? this.state.picture
                          : defaultPicture
                      }
                      alt="product"
                    />
                  </div>

                  <input
                    ref={fileInput => (this.fileInput = fileInput)}
                    style={{ display: "none" }}
                    type="file"
                    onChange={event => this.handleImageChange(event)}
                  />

                  <Button
                    className= 'change-fund-image'
                    onClick={() => this.fileInput.click()}
                  >CHANGE IMAGE</Button>
                </div>

                <div className="funds-form-right">
                  <h2 className="funds-form-header" >CREATE CASH FUND</h2>
                  <h3 className="funds-form-sub-head" >ADD CASH FUND</h3>
                  <h4 className="ff-learn-more">Learn More</h4>
                  <label className="label-size">ITEM NAME</label>
                  <Form.Input className="fund-form-input" onChange={this.handleInputs} name="name" type='text' />
                  <label className="label-size">LET GUEST CONTRIBUTE : </label>
                  <Form.Input className="fund-form-input" onChange={this.handleInputs} name="contribute" type='number' step="0.01" />

                  <Form.Field className="field-flex">
                    <Radio
                      checked={hide === true}
                      onClick={this.handleHide}
                    />
                    <label className="label-size">Hide Total From Guest</label>
                  </Form.Field>
                  <Form.Field className="field-flex">
                    <Radio
                      checked={mostWanted === true}
                      onClick={this.handleWanted}
                    />
                    <label className="label-size">Mark as 'Our Most Wanted'</label>

                  </Form.Field>
                  <div style={{height: "1vw"}}></div>
                  <label className="label-size-1">Note for guest: (optional)</label>
                  <textarea rows="2" className="fund-form-textArea"/>
                  <div style={{display: 'flex', justifyContent: 'space-around',padding:" 3vw 0 0 0" }}>
                    <Button className='fund-form-button' >ADD TO REGISTERY</Button>
                    <Button className='fund-form-button' onClick = {()=>this.hideForm()} >CANCEL</Button>
                  </div>
                </div>
              </div>
            </Form>

          </div>

        </section>
      </div>
    );
  }

}

export default Funds;
