import React, { Component } from 'react';
import Toys from './categories/Toys.js'
import Funds from './categories/Funds.js'
import Essentials from './categories/Essentials.js'
import Classes from './categories/Classes.js'
import Games from './categories/Games.js'
import Donate from './categories/Donate.js'
import Subscription from './categories/Subscription.js'
import swiggle from "../assets/HomePage/Swiggle_line.svg";
import { Route, Link } from "react-router-dom";
import '../App.css'

class Categories extends Component {

  render() {
    return (
      <div>
        <div className="categories-nav">
          <Link className="cata-links cata-toys" to="/categories/toys" >
            <h4 className=" cata-header" >Toys</h4>
            <img
              alt="swiggle"
              src={swiggle}
              className="cata-swiggle cata-toys-swiggle"
            />
          </Link>

          <Link className="cata-links cata-funds" to="/categories/funds" >
            <h4 className=" cata-header" >Funds</h4>
            <img
              alt="swiggle"
              src={swiggle}
              className="cata-swiggle cata-funds-swiggle"
            />
          </Link>

          <Link className="cata-links cata-essentials" to="/categories/essentials" >
            <h4 className=" cata-header" >Essentials</h4>
            <img
              alt="swiggle"
              src={swiggle}
              className="cata-swiggle cata-essentials-swiggle"
            />
          </Link>

          <Link className="cata-links cata-classes" to="/categories/classes" >
            <h4 className=" cata-header" >Classes</h4>
            <img
              alt="swiggle"
              src={swiggle}
              className="cata-swiggle cata-classes-swiggle"
            />
          </Link>

          <Link className="cata-links cata-games" to="/categories/games" >
            <h4 className=" cata-header" >Game</h4>
            <img
              alt="swiggle"
              src={swiggle}
              className="cata-swiggle cata-games-swiggle"
            />
          </Link>

          <Link className="cata-links cata-donate" to="/categories/donate" >
            <h4 className=" cata-header" >Donate</h4>
            <img
              alt="swiggle"
              src={swiggle}
              className="cata-swiggle cata-donate-swiggle"
            />
          </Link>

          <Link className="cata-links cata-subscription" to="/categories/subscription" >
            <h4 className=" cata-header" >Subscription</h4>
            <img
              alt="swiggle"
              src={swiggle}
              className="cata-swiggle cata-subscription-swiggle"
            />
          </Link>
        </div>


        <Route path="/categories/toys" render={()=><Toys />} />

        <Route path="/categories/funds" render={()=><Funds/>}/>

        <Route path="/categories/essentials" render={()=><Essentials/>}/>

        <Route path="/categories/classes" render={()=><Classes/>}/>

        <Route path="/categories/games" render={()=><Games />} />

        <Route path="/categories/donate"render={()=><Donate />} />

        <Route path="/categories/subscription"render={()=><Subscription />} />

      </div>
    );
  }

}

export default Categories;
