import React, { Component } from 'react';
import {Form, Button} from 'semantic-ui-react'

class TempLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      pass: ''
    };
  }
  handleInput = (event) => {
    const element = event.target
    const name = element.name
    const value = element.value
    this.setState({[name]: value})
  }

  render() {
    return (
      <div>
        <Form>
          <Form.Field>
            <label>User</label>
            <input onChange={this.handleInput} name="user" placeholder='User' />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <input onChange={this.handleInput} name="pass" type="password" placeholder='Password' />
          </Form.Field>
          <Button type='submit'>Submit</Button>
        </Form>
      </div>
    );
  }

}

export default TempLog;
