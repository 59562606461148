import React, { Component } from "react";
import logo from "../assets/boonzie.png";
import { Link } from "react-router-dom";
import swiggle from "../assets/HomePage/Swiggle_line.svg";
import StartHere from './StartHere.js'
import SignIn from './SignIn.js'
import "../App.css";

class NavBar extends Component {
  render() {
    return (
      <nav>
        <Link to="/" className="logo-link" ><img alt="logo" className="logo" src={logo} /></Link>

        <div className="nav-contain">

          <StartHere isStartHere={false} />

          <Link className="nav-animation-contain invite">
            <h3 className="router-links" to="/invite">
              Invite
            </h3>
            <img
              alt="swiggle"
              className="swiggle-style invite-swiggle"
              src={swiggle}
            />
          </Link>
        </div>

        <SignIn element={<h3 className="sign-in">Sign In</h3>}/>

      </nav>
    );
  }
}

export default NavBar;
