import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'

class SingleFund extends Component {

  render() {
    return (
      <div className = 'single-fund-contain-box'>
        <Button
          circular
          color="yellow"
          className="fund-add"
          icon="add"
          size="massive"
        />
      </div>
    );
  }

}

export default SingleFund;
