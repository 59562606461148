import React, { Component } from 'react';
import { Modal, Form, Button } from 'semantic-ui-react'
import '../App.css'

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSign = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <Modal className="modal" size='large' trigger={this.props.element}>
        <div className="modal-div">
          <h1 className = 'welcome-header'>WELCOME BACK!</h1>

          <Form className="modal-form-contain">
            <label style={{fontSize: "1.3vw" }}>USERNAME / EMAIL</label>
            <Form.Input className="modal-input" name="username" onChange = {this.handleSign}/>
            <label style={{fontSize: "1.3vw" }}>PASSWORD</label>
            <Form.Input className="modal-input" name='password' type='password' onChange = {this.handleSign}/>
            <div style={{textAlign: 'center'}}>
              <Button className="sign-in-button" type="submit">SIGN IN</Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }

}

export default SignIn;
