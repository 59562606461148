import { toast } from "react-toastify";
import React, { Component } from 'react';
import { Card } from "semantic-ui-react";
import swiggle from "../assets/HomePage/Swiggle_line.svg";
import swiggle_y from '../assets/HomePage/Swiggle_Yellow.svg';
import plus from '../assets/AdminPage/plus.svg';
import minus from '../assets/AdminPage/minus.svg';
import bumblebee from '../assets/images/admin/bumblebee_transformers.png';
import toy2 from '../assets/images/admin/zi_yellow.jpg';
import brown from '../assets/images/admin/05151397_zi_brown.jpg';
import buzz from '../assets/images/admin/2019-walking-buzz.jpg';

export default class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      api: props.api,
      collectionOptions: [],
      count: 10,
      products: [],
    };
  }

  componentDidMount() {
    this.getCollections();
    this.getProducts();
  }

  renderProducts = () => {
    let cnt = [];
    for (let i = 0; i < this.state.products.length; i++) {
      cnt.push(
        <div
          className="cell"
          key={this.state.products[i]['id']}
        >
          <div className="img-overlay circle index2">
            <p className="vertical-center">{this.state.products[i]['store_quantity']}</p>
          </div>
          <div className="img-overlay plus index2">
            <img
              alt="plus sign"
              className="img-overlay-pointer"
              src={plus}
              onClick={this.addItem}
            />
          </div>
          <div className="img-overlay minus">
            <img
              alt="plus sign"
              className="img-overlay-pointer"
              src={minus}
              onClick={this.addItem}
            />
          </div>
          <img
            className="admin-collection-image"
            alt={this.state.products[i]['name']}
            src={this.state.products[i]['image']}
          />
        </div>
      );
    }
    console.log(cnt);
    return cnt;
  }

  getProducts = () => {
    let url = this.state.api + 'products';
    let xhr = new XMLHttpRequest();
    xhr.responseType = "json";
    xhr.open("GET", url);
    xhr.send();
    xhr.onload = () => {
      let response = xhr.response;
      if (xhr.status === 200) {
        console.log(response);
        this.setState({ products: response['data'] });
        toast.success("Products successfully loaded");
      }
      if (xhr.status === 500) {
        console.log("Internal server error");
        toast.error("There was an error while loading the products.");
      }
    }
    console.log("getProducts Finished");
  }

  getCollections = async () => {
    let inner = async () => {
      return new Promise((resolve, reject) => {
        let url = this.state.api + 'products/choices';
        let xhr = new XMLHttpRequest();
        xhr.responseType = "json";
        xhr.open("GET", url);

        xhr.send();
        xhr.onload = () => {
          let response = xhr.response;
          if (xhr.status === 200) {
            for (let x = 0; x < response["collection"].length; x++) {
              let arr = response["collection"][x];
              this.state.collectionOptions.push(
                <div
                  key={arr[0]}
                  className="nav-animation-contain admin-collection"
                >
                  <h3
                    className="router-links"
                    onClick={this.collectionSelection}
                  >
                    {arr[1]}
                  </h3>
                  <img
                    src={swiggle}
                    alt="collection swigg2"
                    className="swiggle-style admin-collection-swiggle"
                  />
                </div>
              );
            }
            toast.success("Information loaded");
            resolve('resolved');
          } else {
            toast.error("There was an error. Please refresh the page.");
            reject('rejected');
          }
        };
      });
    }
    await inner();
    this.forceUpdate();
  }

  collectionSelection = (event) => {
    event.preventDefault();
  }

  addItem = (event) => {
    console.log('addItem called');
    this.setState({ count: this.state.count + 1 });
  }

  subtractItem = (event) => {
    console.log('subtracItem called');
    if (this.state.count !== 0)
      this.setState({ count: this.state.count - 1 });
  }

  renderPlus = () => {
    return (
      <img
        alt="plus sign"
        className="img-overlay-pointer plus"
        src={plus}
        onClick={this.addItem}
      />
    );
  }

  renderMinus = () => {
    return (
      <img
        alt="minus sign"
        className="img-overlay-pointer minus"
        src={minus}
        onClick={this.subtractItem}
      />
    );
  }

  render() {
    return (
      <div className="admin-contain">
        <section className="admin-top-section index2">
          <h1 className="admin-heading">BOONZIE</h1>
        </section>
        <img alt="swiggle line" className="swiggle-line" src={swiggle_y} />
        <div className="admin-collections">
          {this.state.collectionOptions}
        </div>
        <div className="wrapper">
          {this.renderProducts()}
        </div>
        {/* <div className="admin-content-container">
          <div className="row">
            <div className="column">
              <div className="img-overlay circle index2">
                <p className="vertical-center">{this.state.count}</p>
              </div>
              <img className="admin-collection-image" alt="toy_1" src={bumblebee} />
              {this.renderPlus()}
              {this.renderMinus()}
            </div>
            <div className="column">
              <div className="img-overlay circle index2">
                <p className="vertical-center">{this.state.count}</p>
              </div>
              <img className="admin-collection-image" alt="toy_2" src={toy2} />
              {this.renderPlus()}
              {this.renderMinus()}
            </div>
            <div className="column">
              <div className="img-overlay circle index2">
                <p className="vertical-center">{this.state.count}</p>
              </div>
              <img className="admin-collection-image" alt="toy_3" src={brown} />
              {this.renderPlus()}
              {this.renderMinus()}
            </div>
            <div className="column">
              <div className="img-overlay circle index2">
                <p className="vertical-center">{this.state.count}</p>
              </div>
              <img className="admin-collection-image" alt="toy_4" src={buzz} />
              {this.renderPlus()}
              {this.renderMinus()}
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}
