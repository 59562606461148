import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react'
import SignIn from './SignIn.js'

class Footer extends Component {

  render() {
    return (
      <footer>
        <section className='footer-section'>

          <ul className="footer-list" >
            <li className="list-style1">Sign Up</li>
            <SignIn element={<li className="list-style1">Sign In</li>} />
            <li className="list-style1">Contact Us</li>
            <li className="list-style1">About Us</li>
            <li className="list-style1">Why Boonzie?</li>
            <li className="list-style1">Blog</li>
          </ul>

          <ul className="footer-list" >
            <li className="list-style1">Become a Vendor</li>
            <li className="list-style1">Vendor Log In</li>
            <li className="list-style1">Why Become a Vendor</li>
          </ul>

        </section>

        <div className = "footer-bottom">

          <div className="footer-bottom-left" >
            <Icon className="copy-logo" name="copyright outline"/>
            <p className='rights-reserved'>Boonzie, all rights reserved</p>
          </div>

          <div className="footer-bottom-list">

            <p className="list-style2" style={{margin:"1% 0 0 0"}} >Terms of Use</p>
            <p className="list-style2" >Legal</p>
            <p className="list-style2" >Privacy Policy</p>
            <p className="list-style2" >About Boonzie</p>
            <p className="list-style2" style={{border: "none"}}>Children's Online Privacy</p>

          </div>

        </div>

      </footer>
    );
  }

}

export default Footer;
