import React, { Component } from 'react';

class Toys extends Component {

  render() {
    return (
      <div>
        The Toys
      </div>
    );
  }

}

export default Toys;
